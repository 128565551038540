import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import './index.css';
import Root from "./routes/root";
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// PAGES
import ErrorPage from "./error-page";
import DiscoverPage from './routes/discover-page';
import MessagesPage from './routes/messages-page';
import SettingsPage from './routes/settings-page';
import ProfilePage from './routes/profile-page';
import QAPage from './routes/qa-page';
import SkillsPage from './routes/skills-page';
import DevsPage from './routes/devs-page';
import LoginPage from './routes/login-page';
import SearchPage from './routes/search-page';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-VEZNK906JE');

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <DiscoverPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/messages",
        element: <>
          <MessagesPage />
        </>,
      },
      {
        path: "/settings",
        element: <SettingsPage />,
      },
      {
        path: "/dev/:id",
        element: <ProfilePage />,
      },
      {
        path: "/skills",
        element: <SkillsPage />,
      },
      {
        path: "/devs",
        element: <DevsPage />,
      },
      {
        path: "/qa",
        element: <QAPage />,
      },
      {
        path: "/search",
        element: <SearchPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
