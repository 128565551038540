import { useState, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import Topbar from '../components/topbar/topbar';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import useUserStore from '../stores/userStore';
import { getUser } from '../services/userService';
import AlertModal from '../modals/alert-modal/alert-modal';
import useAppStore from '../stores/appStore';
import useUserSettingsStore from '../stores/userSettingsStore';
import ReactGA from 'react-ga4';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import Chatbot from '../components/chat-bot/chat-bot';

const Root = () => {
    const location = useLocation();

    // Stores
    const { alertModal } = useAppStore();
    const { user, setUser, removeUser } = useUserStore();
    const { removeUserSettings } = useUserSettingsStore();

    // States
    const [lastVisible, setLastVisible] = useState<number>(Date.now());
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
    }, [location]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setLastVisible(Date.now());
            } else if (document.visibilityState === 'visible') {
                const now = Date.now();
                const diff = now - lastVisible;

                if (diff > 1800000) { // 30 min
                    runCheckAuth();
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [lastVisible]);

    const runCheckAuth = async () => {
        const user = await getUser();
        if (user) {
            setUser(user);
        } else {
            removeUser();
            removeUserSettings();
        }
    };

    useEffect(() => {
        runCheckAuth();
    }, []);

    useEffect(() => {
        const isMobileView = () => window.innerWidth < 992;

        if (isMobileView()) {
            document.body.style.overflow = isSidebarVisible ? 'hidden' : 'auto';
        }

        return () => {
            if (isMobileView()) {
                document.body.style.overflow = 'auto';
            }
        };
    }, [isSidebarVisible]);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SolflareWalletAdapter(),
        ],
        [WalletAdapterNetwork.Mainnet],
    );

    return (
        <ConnectionProvider endpoint={process.env.REACT_APP_RPC_URL_ENDPOINT as string}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {user && (
                        <Chatbot />
                    )}
                    <Topbar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
                    <div className="container-fluid">
                        <div className="row">
                            <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
                            <div className="col-lg-10" id="detail">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                    {alertModal && (
                        <AlertModal settings={alertModal} />
                    )}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default Root;
