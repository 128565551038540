import React, { useState } from "react";
import {
    ChatContainer,
    ChatHeader,
    ChatInput,
    ChatMessages,
    ChatToggleButton,
    CloseButton,
    InputField,
    MessageBubble,
    SendButton,
    StatusDot,
    Timestamp,
} from "./chat-bot.styles";
import LogoHorizontal from "../../assets/images/ask-a-dev-horizontal.png";
import { fetchStreamResponse } from "../../services/chatbotService";
import DOMPurify from "dompurify";

const Chatbot: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([
        { id: 1, text: "Hello! How can I assist you today?", isUser: false, time: "7:20" },
    ]);
    const [input, setInput] = useState("");

    const sendMessage = async () => {
        if (!input.trim()) return;

        const userMessage = {
            id: messages.length + 1,
            text: input,
            isUser: true,
            time: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
        };

        // Add the user message to the chat
        setMessages([...messages, userMessage]);
        setInput(""); // Clear the input field

        let botMessageId = messages.length + 2;

        // Remove existing "Typing..." placeholder if it exists
        setMessages((prev) =>
            prev.filter((msg) => msg.text !== "Typing...")
        );

        // Add a new "Typing..." placeholder
        setMessages((prev) => [
            ...prev,
            {
                id: botMessageId,
                text: "Typing...",
                isUser: false,
                time: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
            },
        ]);

        try {
            // Fetch the response from the stream API
            await fetchStreamResponse(input, (chunk) => {
                setMessages((prev) =>
                    prev.map((msg) =>
                        msg.id === botMessageId
                            ? { ...msg, text: msg.text === "Typing..." ? chunk : msg.text + chunk } // Replace or append chunk
                            : msg
                    )
                );
            });
        } catch (error) {
            console.error("Error fetching chatbot response:", error);
        }
    };

    return (
        <>
            <ChatToggleButton onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? "−" : "💬"}
            </ChatToggleButton>
            <ChatContainer isOpen={isOpen}>
                <ChatHeader>
                    <div className="d-flex align-items-center">
                        <img width={120} src={LogoHorizontal} alt="Chatbot Logo" />
                        <StatusDot />
                    </div>
                    <CloseButton onClick={() => setIsOpen(false)} aria-label="Close Chat">
                        ×
                    </CloseButton>
                </ChatHeader>
                <ChatMessages>
                    {messages.map((msg) => (
                        <div
                            key={msg.id}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: msg.isUser ? "flex-end" : "flex-start",
                            }}
                        >
                            <MessageBubble isUser={msg.isUser} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg.text) }} />
                            <Timestamp isUser={msg.isUser}>{msg.time}</Timestamp>
                        </div>
                    ))}
                </ChatMessages>
                <ChatInput>
                    <InputField
                        type="text"
                        placeholder="Type your message here..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && !e.shiftKey && sendMessage()}
                    />
                    <SendButton onClick={sendMessage}>Send</SendButton>
                </ChatInput>
            </ChatContainer>
        </>
    );
};

export default Chatbot;
