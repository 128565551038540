import { API_URL } from "../utils/constants";

export const fetchStreamResponse = async (
  message: string,
  callback: (data: string) => void
) => {
  try {
    const response = await fetch(`${API_URL}/chatbot/stream`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message }),
      credentials: "include", // Include cookies for session management
    });

    if (!response.ok) {
      throw new Error("Failed to fetch stream response.");
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error("ReadableStream not supported in this environment.");
    }

    const decoder = new TextDecoder("utf-8");
    let done = false;

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;

      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        console.log("Chunk received:", chunk);
        callback(chunk); // Pass each chunk to the callback
      }
    }

    console.log("Stream ended.");
  } catch (error) {
    console.error("Error fetching chatbot message:", error);
  }
};
