import styled from "styled-components";

// Styled components
export const ChatContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 93%;
  height: 80%;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;

  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 1440px) {
    width: 40%;
  }
`;

export const ChatHeader = styled.div`
  background-color: #5b21b6;
  color: #ffffff;
  padding: 14px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
`;

export const StatusDot = styled.span`
  width: 8px;
  height: 8px;
  background-color: #36d972;
  border-radius: 50%;
  margin-left: 10px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
`;

export const ChatMessages = styled.div`
  flex: 1;
  background-color: #131313;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* Keeps messages in a column layout */
  gap: 8px; /* Adds space between messages */
`;

export const MessageBubble = styled.div<{ isUser: boolean }>`
  max-width: 80%;
  padding: 8px 12px;
  border-radius: 12px;
  word-wrap: break-word;
  background-color: ${(props) => (props.isUser ? "#E5E7EB" : "#5B21B6")};
  color: ${(props) => (props.isUser ? "#333" : "#FFF")};

  /* Align user messages to the right */
  align-self: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  text-align: ${(props) => (props.isUser ? "right" : "left")};
  margin-left: ${(props) => (props.isUser ? "auto" : "0")};

  @media (min-width: 1440px) {
    max-width: 90%;
  }
`;

export const Timestamp = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isUser",
})<{ isUser: boolean }>`
  margin-top: 4px;
  font-size: 10px;
  color: #999;
  text-align: ${(props) => (props.isUser ? "right" : "left")};
  text-align: ${(props) => (props.isUser ? "right" : "left")};
`;

export const ChatInput = styled.div`
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #ffffff;
`;

export const InputField = styled.input`
  && {
    flex: 1;
    padding: 8px;
    border: none;
    outline: none;
    border-radius: 12px;
    background-color: #f1f1f1;
    margin-right: 10px;
    font-size: 14px;
    color: black;

    &:focus {
      background-color: #e6e6e6;
      box-shadow: none; /* Remove Bootstrap glow effect */
      outline: none; /* Remove default outline */
    }
  }
`;

export const SendButton = styled.button`
  background-color: #4f46e5;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #3b35c1;
  }
`;

export const ChatToggleButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4f46e5;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
`;
